<template>
	<div class="newbie">
		<div class="cleverHeader">
			<img class="icon" src="/img/icon-white.png" />
		</div>
		<div class="info">While your invite has been accepted, unfortunately, there are no choices for you to make yet at this time.</div>
		<div class="info">Please check back later or contact your group administrator to make sure the group subscription is up to date.</div>
		<button class="btn" @click="acceptInvite()">Accept Another Invite</button><br /><br />
		<button class="btn" @click="returnToLogin()">Return to Login</button>
		<div class="info">Enjoy your day.</div>
		<div class="info">--M.</div>
	</div>
</template>

<script>
export default {
	name: "NoChoice",
	methods: {
		acceptInvite: function () {
			this.$router.push("/new-user");
		},
		returnToLogin: function () {
			localStorage.removeItem("token");
			this.$services.token = '<none>';
			this.$router.push('/login')
		}
	}
}

</script>

<style scoped>
.newbie {
	text-align: center;
}

.cleverHeader {
	text-align: center;
}

.icon {
	margin-top: 10px;
	width: 30vw;
}

.info {
	margin: 10px;
	text-align: center;
	font-size: 18px;
}

.btn {
	font-size: 18px;
	background-color: black;
	color: #6699ff;
	padding: 5px;
	border: 1px solid #6699ff;
	cursor: pointer;
}
</style>
